export function newParceria() {
    return {
        data_inicio: '',
        data_termino: '',
        nome: '',
        descricao: '',
        company_id: 0,
        status_id: 0,
    };
}
